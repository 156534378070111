.root {
    margin: 0%;
}

.boxItem {
    padding-right: 16px;
    // height: 30px;
}

.buttonCreate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.btIcon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.button {
    display: inline-block;
}
.moreVertIcon {
    display: flex;
    justify-content: flex-start;
}

.lBox {
    display: flex;
    margin-left: auto;
}
.numItem {
    padding-top: 18px;
    display: flex;
    align-self: end;
}
.tableRow {
    table-layout:  fixed;
    width: 100%;
}
.boxtable {
    display: grid;
    table-layout:  fixed;
}

@media only screen and (max-width: 767px) {
    .root {
      display: block;
    }
  
    .app {
      min-height: calc(100vh - 56px);
    }
  
    .main {
      padding: 48px 16px;
    }

    .box {
        display: block;
        
    }

    .boxItem {
        width: 100%;
        margin-bottom: 20px;
        padding: 0%;
        margin-right: 0%;
    }

    .boxItem button{
        height: 48px;
    }

    .buttonCreate {
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .btIcon{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .buttonCreate button{
        width: 100%;
        height: 48px;
    }

    .numItem {
        padding-top: 10px;
        vertical-align: bottom;
        text-align: start;
    }

    .button {
        width: 100%;
    }
  }
