.colorBox {
    width: 30px;
    height: 30px;
    background-color:black;   
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }
