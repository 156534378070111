.root {
  display: flex;
  min-height: 100vh;
  background-color: #FBFCFC;
}

.app {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  padding: 48px 56px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.drawer {
  width: 256px;
  flex-shrink: 0;
}

@media only screen and (max-width: 960px) {
  .root {
    display: block;
  }

  .app {
    min-height: calc(100vh - 56px);
  }

  .main {
    padding: 48px 16px;
  }
}