.box {
  text-align: center;
  color: #fff;
  width: 100%;
  max-width: 600px;
}

.title {
  font-weight: 500;
}

.button {
  height: 56px;
  font-weight: 500;
}

.link {
  text-align: center;
}