.footer {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white {
  color: #ffffff
}

.black {
  color: #000000
}

.logo {
  height: 18px;
  width: auto;
}

.link {
  display: contents;
}

.text {
  margin-right: 5px;
}