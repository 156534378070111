@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  position: relative;
  background-color: #fff;
  padding: 0;
  margin: 0;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(255, 255, 255, 0);
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.5) inset;
  transition: background-color 5000s ease-in-out 0s;
}

a {
  text-decoration: none;
}