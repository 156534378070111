@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
.Footer_footer__7dIj9{margin-bottom:10px;display:flex;justify-content:center;align-items:center}.Footer_white__2AXsu{color:#ffffff}.Footer_black__1Mfha{color:#000000}.Footer_logo__3b2YI{height:18px;width:auto}.Footer_link__9_ojg{display:contents}.Footer_text__2zIhj{margin-right:5px}

.NotLoginLayout_root__3rZI3{min-height:100vh;background-color:#000;display:flex;flex-direction:column;flex:1 1;position:relative}.NotLoginLayout_app__iqYjX{flex:1 1;display:flex;flex-direction:column}.NotLoginLayout_main__2lJdD{flex:1 1;padding:48px 32px;display:flex;flex-direction:column;justify-content:center;align-items:center}@media only screen and (max-width: 960px){.NotLoginLayout_root__3rZI3{display:block}.NotLoginLayout_app__iqYjX{min-height:100vh}.NotLoginLayout_main__2lJdD{padding:48px 16px}}

.ProjectLayout_root__-FyAK{display:flex;min-height:100vh;background-color:#FBFCFC}.ProjectLayout_app__19Klf{overflow:hidden;flex:1 1;display:flex;flex-direction:column}.ProjectLayout_main__2UIEB{flex:1 1;padding:48px 56px;display:flex;flex-direction:column;position:relative}.ProjectLayout_drawer__13q6l{width:256px;flex-shrink:0}@media only screen and (max-width: 960px){.ProjectLayout_root__-FyAK{display:block}.ProjectLayout_app__19Klf{min-height:calc(100vh - 56px)}.ProjectLayout_main__2UIEB{padding:48px 16px}}

.LoginPage_box__3SfIs{text-align:center;color:#fff;width:100%;max-width:600px}.LoginPage_title__3mGgL{font-weight:500}.LoginPage_button__3x_vQ{height:56px;font-weight:500}.LoginPage_link__BHBA3{text-align:center}

.ForgotPass_box__2uJLC{text-align:center;color:#fff;width:100%;max-width:600px}.ForgotPass_title__2hZa6{font-weight:500}.ForgotPass_button__2bLB0{height:56px;font-weight:500}.ForgotPass_link__3-L78{text-align:center}

.NewPass_box__2C-hw{text-align:center;color:#fff;width:100%;max-width:600px}.NewPass_title__1Kwik{font-weight:500}.NewPass_button__3LmjD{height:56px;font-weight:500}.NewPass_link__128Ap{text-align:center}

.Error_button__23uWU{height:56px;font-weight:500}

.Contact_contact__hRSB-{color:#ffffff}.Contact_button__1F7H1{height:56px;font-weight:500;width:80%}

.colorBox {
    width: 30px;
    height: 30px;
    background-color:black;   
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
  }

.ListTable_root__2ap7O{margin:0%}.ListTable_boxItem__1eEP1{padding-right:16px}.ListTable_buttonCreate__2Gc-h{display:flex;justify-content:flex-end;align-items:center}.ListTable_btIcon__1c0EI{display:flex;justify-content:space-between;align-items:center}.ListTable_button__2YHJS{display:inline-block}.ListTable_moreVertIcon__kdGEJ{display:flex;justify-content:flex-start}.ListTable_lBox__2hL8D{display:flex;margin-left:auto}.ListTable_numItem__Ph2QI{padding-top:18px;display:flex;align-self:end}.ListTable_tableRow__2vEZ6{table-layout:fixed;width:100%}.ListTable_boxtable__11Ami{display:grid;table-layout:fixed}@media only screen and (max-width: 767px){.ListTable_root__2ap7O{display:block}.ListTable_app__18uq_{min-height:calc(100vh - 56px)}.ListTable_main__1R5FM{padding:48px 16px}.ListTable_box__3gQ1x{display:block}.ListTable_boxItem__1eEP1{width:100%;margin-bottom:20px;padding:0%;margin-right:0%}.ListTable_boxItem__1eEP1 button{height:48px}.ListTable_buttonCreate__2Gc-h{display:flex;margin-top:10px;justify-content:flex-end;margin-bottom:20px}.ListTable_btIcon__1c0EI{width:100%;display:flex;flex-direction:column;justify-content:flex-start}.ListTable_buttonCreate__2Gc-h button{width:100%;height:48px}.ListTable_numItem__Ph2QI{padding-top:10px;vertical-align:bottom;text-align:start}.ListTable_button__2YHJS{width:100%}}

.PageTable_root__1nMoC{margin:0%}.PageTable_boxItem__1CMmg{padding-right:16px}.PageTable_buttonCreate__3Z590{display:flex;justify-content:flex-end;align-items:center}.PageTable_btIcon__OnGAF{display:flex;justify-content:space-between;align-items:center}.PageTable_button__27WO-{display:inline-block}.PageTable_moreVertIcon__3wGtM{display:flex;justify-content:flex-start}.PageTable_lBox__11QNS{display:flex;margin-left:auto}.PageTable_numItem__1Lg44{padding-top:18px;display:flex;align-self:end}.PageTable_tableRow__1-3B7{table-layout:fixed;width:100%}.PageTable_boxtable__3OnAc{display:grid;table-layout:fixed}@media only screen and (max-width: 767px){.PageTable_root__1nMoC{display:block}.PageTable_app__u2Sd7{min-height:calc(100vh - 56px)}.PageTable_main__1vp4D{padding:48px 16px}.PageTable_box__3w7p0{display:block}.PageTable_boxItem__1CMmg{width:100%;margin-bottom:20px;padding:0%;margin-right:0%}.PageTable_boxItem__1CMmg button{height:48px}.PageTable_buttonCreate__3Z590{display:flex;margin-top:10px;justify-content:flex-end;margin-bottom:20px}.PageTable_btIcon__OnGAF{width:100%;display:flex;flex-direction:column;justify-content:flex-start}.PageTable_buttonCreate__3Z590 button{width:100%;height:48px}.PageTable_numItem__1Lg44{padding-top:10px;vertical-align:bottom;text-align:start}.PageTable_button__27WO-{width:100%}}

body{position:relative;background-color:#fff;padding:0;margin:0}input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus input:-webkit-autofill,textarea:-webkit-autofill,textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,select:-webkit-autofill,select:-webkit-autofill:hover,select:-webkit-autofill:focus{border:1px solid rgba(255,255,255,0);-webkit-text-fill-color:inherit;-webkit-box-shadow:0 0 0px 1000px rgba(255,255,255,0.5) inset;-webkit-transition:background-color 5000s ease-in-out 0s;transition:background-color 5000s ease-in-out 0s}a{text-decoration:none}

